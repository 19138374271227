import Dropzone from "dropzone";

document.addEventListener('alpine:init', () => {
    Alpine.store('cct', {
        setDefaultCouncil(id) {
            axios({
                method: 'post',
                url: '/user/set-default-council',
                data: {id: id}
            }).then((response) => {
                let text1 = window.location.href;
                let dcarbon_factor = text1.includes("carbon-factor");
                let defect_codes = text1.includes("defect_codes");
                let treatment = text1.includes("treatment");
                let gang_type = text1.includes("gang_type");
                let gang = text1.includes("gang");
                if (dcarbon_factor == true) {
                    window.location.href = "/carbon-factor/" + id;
                    return false;
                } else if (defect_codes == true) {
                    window.location.href = "/defect_codes/" + id;
                    return false;
                } else if (treatment == true) {
                    window.location.href = "/treatment/" + id;
                    return false;
                } else if (gang_type == true) {
                    window.location.href = "/gang_type/" + id;
                    return false;
                } else if (gang == true) {
                    window.location.href = "/gang/" + id;
                    return false;
                } else {
                    location.reload();
                    return false;
                }
            });
        },
        addJobs: {
            fileImport: {
                init() {
                    let myDropzone = new Dropzone("#dropzone", {
                        url: "/file-import",
                        headers: {'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content},
                        maxFilesize: 10,
                        renameFile: function (file) {
                            document.getElementById("file-import-results").style.color = "";
                            document.getElementById("file-import-results").innerHTML = 'Uploading in progress Please Wait...';
                            var dt = new Date();
                            var time = dt.getTime();
                            return time + file.name;
                        },
                        acceptedFiles: ".xlsx",
                        uploadMultiple: false,
                        addRemoveLinks: true,
                        timeout: null,
                        success: function (file, response) {
                            if (response.outcome == 'error') {
                                document.getElementById("file-import-results").style.color = "red";
                            } else {
                                document.getElementById("file-import-results").style.color = "";
                            }
                            document.getElementById("file-import-results").innerHTML = response.results;
                            return false;
                        },
                        error: function (file, message) {
                            document.getElementById("file-import-results").style.color = "red";
                            if (message.hasOwnProperty("message")) {
                                document.getElementById("file-import-results").innerHTML = message.message;
                            } else {
                                document.getElementById("file-import-results").innerHTML = message;
                            }
                            return false;
                        }
                    });
                }
            },
        },
        countaddJobs: {
            fileImport: {
                init() {
                    let myDropzone = new Dropzone("#dropzone", {
                        url: "/count-fileImport",
                        headers: {'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content},
                        maxFilesize: 10,
                        renameFile: function (file) {
                            document.getElementById("file-import-results").style.color = "";
                            document.getElementById("file-import-results").innerHTML = 'Uploading in progress Please Wait...';
                            var dt = new Date();
                            var time = dt.getTime();
                            return time + file.name;
                        },
                        acceptedFiles: ".xlsx",
                        uploadMultiple: false,
                        addRemoveLinks: true,
                        timeout: null,
                        // addedfile:function (file, response) {},
                        success: function (file, response) {
                            if (response.outcome == 'error') {
                                document.getElementById("file-import-results").style.color = "red";
                            } else {
                                document.getElementById("file-import-results").style.color = "";
                            }
                            document.getElementById("reCBtn").style.display="none";
                            document.getElementById("file-import-results").innerHTML = response.results;
                            document.getElementById("centerReturnBtn").href = response.url;
                            return false;
                        },
                        error: function (file, message) {
                            document.getElementById("file-import-results").style.color = "red";
                            if (message.hasOwnProperty("message")) {
                                document.getElementById("file-import-results").innerHTML = message.message;
                            } else {
                                document.getElementById("file-import-results").innerHTML = message;
                            }

                            return false;
                        }
                    });
                }
            }
        },
    })
})
